import React, {useEffect, useState} from 'react';
import {deposits, deposit_remove} from '../../service/deposit'
import './style.css';


const Deposits = ({user_token, set_menu, authorize}) => {

    const [d_view, setDataView] = useState(null);

    useEffect(() =>{

		authorize(1);

		const lnks = [{'url': '/', 'name': 'Home'}, {'url': '/deposits/add', 'name': 'Create Deposit'}]
		set_menu(lnks)

		let f = "id name description deposit_type product_types"
		let x = deposits(f, user_token).then((r) =>{
			if(r == null){
				return
			}	
			if(r.errors != null){
					if(r.errors.length > 0){
						alert(r.errors[0].message)
						return
					}
					alert("Unexpected Error Contact HWT support...")
					return
			}
			if(r.deposits.error != null){
				alert(r.deposits.error.message)
				return
			}
			setDataView(r.deposits.result)
		}
		);
    }, [])

	const remove = async (id) => {
		let f = "id name description deposit_type product_types"

		const r = await deposit_remove(id, f, user_token);
		if(r.errors != null){
			if(r.errors.length > 0){
				alert(r.errors[0].message)
				return
			}
			alert("Unexpected Error Contact HWT support...")
			return
		}
		if(r.deposit_remove.error != null){
			alert(r.deposit_remove.error.message)
			return
		}
		setDataView(r.deposit_remove.result)
	}

    let tbl_view = null;
	if(d_view != null){

        if(d_view.length == 0){
            return (<div>
                <div className='nothing_here'>
                <p>Nothing to see here <br></br>
                   It looks like you have no deposit rules setup. Click <a href='/deposits/add'>here</a> to create a rule.</p>
                </div>
            </div>)
        }

		let rows = []
		for(let x of d_view){

			let prod_type = [];

			for(const dpt of x.product_types){
				if(dpt == 0){
					prod_type.push("A")
				}
				else if(dpt == 1){
					prod_type.push("F")
				}
				else if(dpt == 2){
					prod_type.push("XF")
				}
				else if(dpt == 3){
					prod_type.push("T")
				}
				else if(dpt == 4){
					prod_type.push("V")
				}
				else if(dpt == 5){
					prod_type.push("PAK")
				}
				else if(dpt == 12){
					prod_type.push("XCP")
				}
				else if(dpt == 13){
					prod_type.push("XAPA")
				}
				else if(dpt == 14){
					prod_type.push("XAL")
				}

			}


            let dtype = '';
            if(x.deposit_type == 0){
                dtype = '£'
            }
            else{
                dtype = '%'
            }


			rows.push(<tr>

				<td>
					{x.name}
				</td>
				<td>
					{dtype}
				</td>
				<td>
					{prod_type.join("-")}
				</td>
				<td>
					<a href={'/deposits/add?did=' + x.id}>Edit</a>
				</td>
                <td>
					<a style={{cursor:"pointer", textDecoration:'underline', color:'red'}} onClick={() => remove(x.id)}>Remove</a>
				</td>
			</tr>)
		}

		tbl_view = <table>
				<thead>
					<td>
						Name
					</td>
					<td>
						Type 
					</td>
					<td>
						Product(s)
					</td>
					<td></td>
                    <td></td>
				</thead>
			<tbody>
				{rows}
			</tbody>
		</table>
	}

    return(<div className='rule_tbl'>
        {tbl_view}
    </div>);


};

export default Deposits;