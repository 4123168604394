import React, {useEffect, useState} from 'react';
import queryString from 'query-string';
import {update_deposit, add_deposit, deposit} from '../../service/deposit'
import '../margin_add/style.css';

const DepositAdd = ({user_token, set_menu, authorize}) => {
    const [discount_d, setDiscountD] = useState(null);

    const [days_due, setDaysDue] = useState(0);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [deposit_type, setDepositType] = useState(0);

    const [prodAcc, setProdAcc] = useState(false);
    const [prodFlight, setProdFlight] = useState(false);
    const [prodTransfer, setProdTransfer] = useState(false);
    const [prodParking, setProdParking] = useState(false);
    const [prodAirportHotel, setProdAirportHotel] = useState(false);
    const [prodLounge, setProdLounge] = useState(false);
    const [prodPackage, setProdPackage] = useState(false);

    const [amount, setAmount] = useState(0);
    const [max_price, setMaxPrice] = useState(0);
    const [per_pax, setPerPax] = useState(true);
    const [onlyProductTypes, setonlyProductTypes] = useState(false);
    const [enabled, setEnabled] = useState(true);
    const [expired, setExpired] = useState("");

    const [btn_lbl, setBtnLbl] = useState('Add');
    const [ready, setReady] = useState(false)

    useEffect(() => {

        authorize(1);
        let x = queryString.parse(document.location.search);

        const lnks = [{'url': '/', 'name': 'Home'}, {'url': '/deposits', 'name': 'Deposits'}]
		
		set_menu(lnks)

        if(x.did == null){
            setReady(true);
            return
        }
        let f = "id days_due name description deposit_type only_product_types product_types max_price per_pax amount payment_method enabled";

        deposit(Number(x.did),f, user_token).then((r) =>{

            if(r == null){
                setReady(true);
				return
			}
			if(r.errors != null){
				if(r.errors.length > 0){
					alert(r.errors[0].message)
					return
				}
				alert("Unexpected Error Contact HWT support...")
				return
			}
			if(r.deposit.error != null){
				alert(r.deposits.error.message)
				return
			}	

            if(r.deposit.result == null){
				return
			}

            setDaysDue(r.deposit.result.days_due);
            setDiscountD(r.deposit.result);
            setName(r.deposit.result.name);
            setDescription(r.deposit.result.description);
            setDepositType(r.deposit.result.deposit_type);
            setAmount(r.deposit.result.amount);
            setPerPax(r.deposit.result.per_pax);

            for(const dpt of r.deposit.result.product_types){
                if(dpt == 0){
                    setProdAcc(true);
                }
                else if(dpt ==1){
                    setProdFlight(true);
                }
                else if(dpt ==3){
                    setProdTransfer(true);
                }
                else if(dpt ==5){
                    setProdPackage(true);
                }
                else if(dpt ==12){
                    setProdParking(true);
                }
                else if(dpt ==13){
                    setProdAirportHotel(true);
                }
                else if(dpt ==14){
                    setProdLounge(true);
                }
            }


            let max_price = r.deposit.result.max_price;
            if(max_price != -1){
                setMaxPrice(max_price);
            }
       
            setBtnLbl('Update');
            setReady(true);
        });
    }, [])




    const addupdate_deposit = async () => {

        let days_due =  Number(document.getElementById("txt_days_due").value);
        let name = document.getElementById("txt_name").value;
        let desc = document.getElementById("txt_desc").value;
        let type = document.getElementById("ddl_type").value;
        let payMethod = document.getElementById("ddl_paymeth").value;
        let amount = Number(document.getElementById("txt_amount").value);
        let max_price =  Number(document.getElementById("txt_maxamount").value);

        if(max_price == 0){
            max_price = -1;
        }

        let ptypes = [];

        let en = 0;
        if(enabled){
            en = 1;
        }

        let po = 0;
        if(onlyProductTypes){
            po = 1;
        }

        let perPax = 0;
        if(per_pax){
            perPax = 1;
        }

        if(prodAcc){
            ptypes.push(0);
        }
        if(prodFlight){
            ptypes.push(1);
        }
        if(prodTransfer){
            ptypes.push(3);
        }
        if(prodPackage){
            ptypes.push(5);
        }
        if(prodParking){
            ptypes.push(12);
        }
        if(prodAirportHotel){
            ptypes.push(13);
        }
        if(prodLounge){
            ptypes.push(14);
        }


        let str_ptypes = "[" + ptypes.join(',') + "]";

        //make graph all and add this sucker
        if(discount_d == null){
            let new_discount = await add_deposit(days_due, name,desc, type, amount, max_price, perPax, str_ptypes, po, payMethod , null,  "id", user_token)
            if(new_discount == null){
                alert("Failed to add deposit. Contact HWT support");
                return
            }
        }
        else{
            let new_discount = await update_deposit(discount_d.id, days_due, name, desc, type, amount, max_price, perPax, str_ptypes, po, payMethod, en, null, "id", user_token)
            if(new_discount == null){
                alert("Failed to update deposit. Contact HWT support");
                return
            }
        }
        window.location.replace("/deposits");
    }

    
    if(!ready){
        return(<div className='input_form'>Loading form...</div>)
    }


    let type_ops = [{"value": 0, "text": "Monetary (£)"}, {"value": 1, "text": "Percentage (%)"}]
    let type_ops_x = [];
    for(let x of type_ops){
        if(deposit_type == x.value){
            type_ops_x.push(<option selected={true} value={x.value}>{x.text}</option>);
        }
        else{
            type_ops_x.push(<option value={x.value}>{x.text}</option>);
        }
    }  
    
    let days_duex = <div className='input_section'>
        <div className='input_label_section'>
            <label>Days Due</label>
        </div>
        <div className='input_field_section small'>
            <input id='txt_days_due' type='number' min={0} onChange={(d) => setDaysDue(d.value)} value={days_due}></input>
        </div>
    </div>

    let namex = <div className='input_section'>
        <div className='input_label_section'>
            <label>Name</label>
        </div>
        <div className='input_field_section large'>
            <input id='txt_name' onChange={(d) => setName(d.value)} value={name}></input>
        </div>
    </div>

    let descriptionx = <div className='input_section'>
        <div className='input_label_section'>
            <label>Description</label>
        </div>
        <div className='input_field_section large'>
            <input id='txt_desc' onChange={(d) => setDescription(d.value)} value={description}></input>
        </div>
    </div>

    let mtype = <div className='input_section'>
          <div className='input_label_section'>
            <label>deposit Type</label>
        </div>
        <div className='input_field_section'>
            <select id='ddl_type' onChange={(d) => setDepositType(d.value)}>
                {type_ops_x}
            </select>
        </div>
    </div>



    let noProdCheck = true;
    let yesProdCheck = false;
    if(onlyProductTypes == 1){
        noProdCheck = false;
        yesProdCheck = true;
    }


    let ptypeAcc = <div className='input_section'>
    <div className='input_label_section'>
        <label>Accommodation</label>
    </div>
    <div className='input_field_section'>
        <input type="checkbox" id="chkProdAcc" onChange={(d) => setProdAcc(d.target.checked)} checked={prodAcc} name="chkProdAcc" ></input>
    </div>
    </div>

    let pTypeFlight = <div className='input_section'>
    <div className='input_label_section'>
        <label>Flights</label>
    </div>
    <div className='input_field_section'>
        <input type="checkbox" id="chkProdFlight" onChange={(d) => setProdFlight(d.target.checked)} checked={prodFlight} name="chkProdFlight" ></input>
    </div>
    </div>

    let pTypeTransfer = <div className='input_section'>
    <div className='input_label_section'>
        <label>Transfer</label>
    </div>
    <div className='input_field_section'>
        <input type="checkbox" id="chkProdTansfer" onChange={(d) => setProdTransfer(d.target.checked)} checked={prodTransfer} name="chkProdTansfer" ></input>
    </div>
    </div>

    let pTypeParking = <div className='input_section'>
    <div className='input_label_section'>
        <label>Parking</label>
    </div>
    <div className='input_field_section'>
        <input type="checkbox" id="chkProdParking" onChange={(d) => setProdParking(d.target.checked)} checked={prodParking} name="chkProdParking" ></input>
    </div>
    </div>

    let pTypeAirportHotel = <div className='input_section'>
    <div className='input_label_section'>
        <label>Airport Accommodation</label>
    </div>
    <div className='input_field_section'>
        <input type="checkbox" id="chkProdAirHotel" onChange={(d) => setProdAirportHotel(d.target.checked)} checked={prodAirportHotel} name="chkProdAirHotel" ></input>
    </div>
    </div>

    let pTypeLounge = <div className='input_section'>
    <div className='input_label_section'>
        <label>Lounge</label>
    </div>
    <div className='input_field_section'>
        <input type="checkbox" id="chkProdLounge" onChange={(d) => setProdLounge(d.target.checked)} checked={prodLounge} name="chkProdLounge" ></input>
    </div>
    </div>

    let pTypePackage = <div className='input_section'>
    <div className='input_label_section'>
        <label>Package</label>
    </div>
    <div className='input_field_section'>
        <input type="checkbox" id="chkProdPackage" onChange={(d) => setProdPackage(d.target.checked)} checked={prodPackage} name="chkProdPackage" ></input>
    </div>
    </div>

    let pProductExclusive = <div className='input_section'>
    <div className='input_label_section'>
        <label>Basket must contain selected products ONLY</label>
    </div>
    <div className='input_field_section'>
        <input type="checkbox" id="chkProdFlight" onChange={(d) => setonlyProductTypes(d.target.checked)} checked={onlyProductTypes} name="chkProdFlight" ></input>
    </div>
    </div>


    let amt = <div className='input_section'>
    <div className='input_label_section'>
        <label>Amount</label>
    </div>
    <div className='input_field_section medium'>
        <input id='txt_amount' onChange={(d) => setAmount(d.value)} type='number' value={amount}></input>
    </div>
    </div>

    let maxprice = <div className='input_section'>
    <div className='input_label_section'>
        <label>Maximum Cost</label>
    </div>
    <div className='input_field_section medium'>
        <input id='txt_maxamount' onChange={(d) => setMaxPrice(d.value)} type='number' value={max_price}></input>
    </div>
    </div>

    let noCheck = true;
    let yesCheck = false;
    if(per_pax == 1){
        noCheck = false;
        yesCheck = true;
    }

    let amt_per_pax = <div className='input_section'>
    <div className='input_label_section'>
    <label>Per Person Costing</label>
    </div>
    <div className='input_field_section'>
        <input type="checkbox" id="chkPerPerson" onChange={(d) => setPerPax(d.target.checked)} checked={per_pax} name="chkPerPerson" ></input>
    </div>
    </div>

    let payMthod = <div className='input_section'>
    <div className='input_label_section'>
    <label>Balance Payment Method</label>
    </div>
    <div className='input_field_section'>
    <select id='ddl_paymeth'>
        <option value={"dd"}>Payment Plan</option>
        <option value={"balance"}>Balance</option>
    </select>
    </div>
    </div>

    let enabledx = <div className='input_section'>
    <div className='input_label_section'>
        <label>Enabled</label>
    </div>
    <div className='input_field_section'>
        <input type="checkbox" id="optOnlyProdNo" onChange={(d) => setEnabled(d.target.checked)} checked={enabled} name="chkEnabled" ></input>
    </div>
    </div>


    let btn = <div className='btn_section'>
        <button onClick={() => {addupdate_deposit()}}>{btn_lbl}</button>
    </div>

    return(<div className='input_form'>
        {days_duex}
        {namex}
        {descriptionx}
        {mtype}


        {ptypeAcc}
        {pTypeFlight}
        {pTypeTransfer}
        {pTypeParking}
        {pTypeAirportHotel}
        {pTypeLounge}
        {pTypePackage}

        {pProductExclusive}

        {amt}
        {maxprice}
        {amt_per_pax}
        {payMthod}
        {enabledx}

        {btn}
    </div>);


};

export default DepositAdd