import {api, graph2, graph_vars, test, test2} from '../utils/shared'


export const deposits = async(fields, token) => {
    let q = `{deposits {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const deposit = async(deposit_id, fields, token) => {
    let q = `{deposit (deposit_id: ${deposit_id}) {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const add_deposit = async(days_due, name, description, deposit_type, amount, max_price, per_pax, product_types, only_product_types, payment_method, expires, fields, token) => {
    let q = `mutation {deposit_add(days_due: ${days_due}, name: "${name}", description: "${description}", deposit_type: ${deposit_type}, 
	amount: ${amount}, max_price: ${max_price}, per_pax: ${per_pax}, product_types: ${product_types}, payment_method: "${payment_method}", only_product_types: ${only_product_types}, expires: ${expires}) {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const update_deposit = async(deposit_id, days_due, name, description, deposit_type, amount, max_price, per_pax, product_types, only_product_types, payment_method, enabled, expires, fields, token) => {
    let q = `mutation {deposit_update(days_due: ${days_due}, name: "${name}", deposit_id: ${deposit_id}, description: "${description}", deposit_type: ${deposit_type}, 
	amount: ${amount}, max_price: ${max_price}, per_pax: ${per_pax}, product_types: ${product_types}, payment_method: "${payment_method}", only_product_types: ${only_product_types} 
	enabled: ${enabled}, expires: ${expires}) {
		result {
            ${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}

export const deposit_remove = async(deposit_id, fields, token) => {
    let q = `mutation {deposit_remove(deposit_id: ${deposit_id}) {
		result {
			${fields}
		}
		error {
			... on Unexpected_Error {
				message
			}
		}
	}}`

    let x = await graph2(q, token)
    return x
}